import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button, Card, CardBody, CardGroup, Col, Container, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { Form, Input, Checkbox, Spin } from 'antd'
import "antd/dist/antd.css";
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined } from '@ant-design/icons';
import geniLogo from "../../assets/images/geniLogo.png";
import { url } from "../../config";
export default class Members extends Component {
  constructor(props) {
    super(props);
    console.log("props are ------------------------------- ", props);
    var params = props.match.params;

    this.state = {
      loading: false,
      member: "member",
      meetingName: params.meetingName,
      url: props.match.url
    }
  }
  handleSubmit = values => {

    console.info("Received values of form: ", values);
    this.setState({ loading: true })
    var obj = {
      name: values.name,
      meetingName: this.state.meetingName,
    }
    axios.post(url + 'createMeeting', obj).then(res => {
      res.data.meetingName = this.state.meetingName;
      if (this.state.member == 'host') {
        res.data.admin = true
      }
      else res.data.admin = false
      res.data.url = this.state.url;
      var resp = JSON.stringify(res.data);
      localStorage.setItem("meeting", resp);
      this.props.history.push("/GalleryView");
    }).catch(err => {
      this.setState({ loading: false })
      if (err.response != undefined) {
        console.log(err.response);
        alert(err.response.data)
      }
      else {
        console.log(err);
        alert(err)
      }

    })


  };
  render() {
    return (
      <div className="app flex-row align-items-center">
        {this.state.loading ? <LoadingOutlined style={{ fontSize: 100, paddingLeft: "42%" }} spin /> : (
          <Container className="App-header" >
            <Row className="justify-content-center" >
              <Col md="6">
                <CardGroup>
                  <Card className="p-10">
                    <CardBody>
                      <Form
                        className="leadform"
                        onFinish={this.handleSubmit} hideRequiredMark="true"  >
                        <img src={geniLogo} />
                        <p className="text-muted">Enter your Name</p>

                        <Form.Item name="name"
                          rules={[

                            {
                              required: true,
                              message: 'Please Enter your Email!',
                            },
                            { max: 15, message: 'It should be within 15 character limit' },
                          ]}
                        >
                          <Input type="text" prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Name" />
                        </Form.Item>

                        <Form.Item>
                          <Row>
                            <Col xs="12">
                        <Button className="btn-primary" block  >{
                           this.state.member == 'host' ? "Start Meeting" : "Join Meeting"
                        }</Button>
                            </Col>
                          </Row>
                        </Form.Item>
                      </Form>

                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>

        )}

      </div>
    );
  }
}

