import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import { Layout, Menu, Row, Col, Badge, Tooltip } from "antd";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined, LoadingOutlined, AudioMutedOutlined, AudioOutlined, EyeOutlined, EyeInvisibleFilled, CloseOutlined, InfoCircleFilled,
    TeamOutlined, DesktopOutlined
} from "@ant-design/icons";
import mute from '../assets/images/mute.png'
import fullView from '../assets/images/fullView.png'
import galleryView from '../assets/images/galleryView.png'
import info from '../assets/images/info.png'
import infoClicked from '../assets/images/infoClicked.png'
import leave from '../assets/images/leave.png'
import muteOff from '../assets/images/muteOff.png'
import participants from '../assets/images/participants.png'
import speakerView from '../assets/images/speakerView.png'
import video from '../assets/images/video.png'
import videoOff from '../assets/images/videoOff.png'
import Item from "antd/lib/list/Item";
const { Header, Footer, Sider, Content } = Layout;

export default class FooterCard extends React.Component {
    constructor(props) {
        super(props);
        console.log("Footer : props are : -----------------", props);
    }

    toggle = () => {
        this.props.toggle()
    };

    render() {
        return (
            <Footer className="site-layout-background-footer" style={{ padding: 0 }}>
                <Row style={{ textAlign:"center"}}>
                    <Col className="" span={2}>

                    </Col>
                    <Col className=" firstCol" span={2}>
                        {
                            this.props.audio ? (<a onClick={this.props.mute}>  <img className="iconImages" src={mute} 
                            // style={{ width: "50%" }} 
                            />
                            </a>) : (<a onClick={this.props.unMute}>  <img className="iconImages" src={muteOff} 
                            // style={{ width: "50%" }}
                             />
                            </a>)
                        }
                        <p className="barText" >Mute</p>

                    </Col>
                    <Col className="" span={1}>

                    </Col>
                    <Col className=" firstCol" span={13}>
                        <Row >
                            <Col className="" span={5}>
                                <Tooltip title={"Meeting Info: https://genimeets.geniteam.com/member/" + this.props.inviteOthersURL + ". Share this link with your members to join this meeting"}>
                                    < img className="iconImages" src={info} 
                                    // style={{ width: "55%" }}
                                     />
                                     
                                    <p className="barText" >info</p>
                                </Tooltip>
                                {/* <a onClick={() => { alert(`http://localhost:3000/${this.props.inviteOthersURL}/member`) }}>  <img className="iconImages" src={info} style={{ width: "55%" }} />
                                    <p className="barText" >info</p></a> */}
                            </Col>
                            <Col className="" span={7}>
                                <Badge count={this.props.attendees ? this.props.attendees.length : 0} className="site-badge-count-4" style={{ backgroundColor: 'green' }}>
                                    <a onClick={this.toggle}>    <img className="iconImages " src={participants}
                                    //  style={{ width: "70%" }} 
                                     />
                                        <p className="barText participantStyle" >Participants</p></a>
                                </Badge>


                            </Col>
                            <Col className="" span={6}>
                                {
                                    this.props.video ? (<a onClick={this.props.deActivateVideo}>  <img className="iconImages" src={video} 
                                    // style={{ width: "87%" }} 
                                    />
                                    </a>) : (<a onClick={this.props.activateVideo}>  <img className="iconImages" src={videoOff} 
                                    // style={{ width: "87%" }}
                                     />
                                    </a>)
                                }

                                <p className="barText videStyleFooter" >Video</p>

                            </Col>
                            <Col className="" span={6}>
                                {this.props.viewType == 'galleryView' ?

                                    (<a onClick={this.props.speaker}>  <img className="iconImages" src={speakerView} 
                                    // style={{ width: "44%" }}
                                     />
                                        <p className="barText speakerStyleFooter" >Speaker</p></a>) : (
                                        <a onClick={this.props.gallery}>  <img className="iconImages" src={galleryView} 
                                        // style={{ width: "44%" }} 
                                        />
                                            <p className="barText " >Gallery View</p></a>
                                    )
                                }


                            </Col>


                        </Row>
                    </Col>
                    <Col className="" span={1}>

                    </Col>
                    <Col className=" firstCol" span={2}>

                        <a onClick={this.props.LeaveMeeting}> <img className="iconImages" src={leave} 
                        // style={{ width: "70%" }} 
                        />
                            <p className="barText" >Leave</p></a>

                    </Col>
                    <Col className="" span={1}>

                    </Col>
                    <Col className={this.props.admin ? " firstCol" : ""} span={2}>
                        {this.props.admin ? (<a onClick={this.props.EndMeeting}> <img className="iconImages" src={leave} 
                        // style={{ width: "70%" }}
                         />
                            <p className="barText" >End</p></a>) : null}
                    </Col>


                </Row>
            </Footer >
        );
    }
}

