import React, { Component, useState } from 'react';
import {
    ConsoleLogger,
    DefaultDeviceController,
    DefaultMeetingSession,
    LogLevel,
    DefaultModality,
    MeetingSessionConfiguration,
    MeetingSessionStatusCode
} from 'amazon-chime-sdk-js';
import { Layout, Menu, Input, Button, Row, Col, List } from 'antd'

import axios from 'axios';
import 'antd/dist/antd.css'
import { url } from "../config"
import ReactPlayer from 'react-player'
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined, LoadingOutlined, AudioMutedOutlined, AudioOutlined, EyeOutlined, EyeInvisibleFilled, CloseOutlined, InfoCircleFilled,
    TeamOutlined, DesktopOutlined
} from '@ant-design/icons';
import fullView from '../assets/images/fullView.png'
// import Header from './header';
import chimeFunctios from "../util/chime"
import logo from "../assets/images/geniLogo.png"
import Navbar from './navbar';
import FooterCard from './Footer';
import SiderCard from './sider';
import ElementFunctions from "./elementsFunctions";

const { Footer, Sider, Content, Header } = Layout;
var meetingSession;
var indexMap = {};
var tilesData = [
]
var numOfPersonels = 6;
var videoFlag = true;
const data = [
];
const galleryView = 'galleryView', speakerView = 'speakerView'
export default class GalleryView extends Component {
    constructor(props) {
        super(props);
        console.log("galleryview : props are : -----------------", props);
        var a = JSON.parse(localStorage.getItem("meeting"));
        this.state = {
            viewType: speakerView,
            url: a.url ? a.url : "/",
            collapsed: false,
            display: true,     // for loadinf screen
            defaultAttendeeID: '',      //the id of the current attendee
            defaultAttendeeName: '',    //the name of the current attendee
            attendeeResponse: a.attendeeResponse,       //attendess response from backened 
            meetingResponse: a.meetingResponse,          //meeting response from backened 
            meetingName: a.meetingName ? a.meetingName : "Meeting",
            admin: a.admin ? a.admin : false,                   // to check if the user is also a admin or not
            test: false,                                        // this variable is for testing duringg developement
            activateVideoByAdmin: false,                            // this variable is to check if admin has enable video for this attendee so we can active it
            attendees: [],                                   //contains all data of all attendees in a session 
            video: true,                                      //video state
            audio: true                                          //audio state
        }
        this.EndMeeting = this.EndMeeting.bind(this);
        this.changeToGalleryView = this.changeToGalleryView.bind(this);
        this.changeToSpeakerView = this.changeToSpeakerView.bind(this);
        this.activateVideo = this.activateVideo.bind(this);
        this.deActivateVideo = this.deActivateVideo.bind(this);
        this.Mute = this.Mute.bind(this);
        this.unMute = this.unMute.bind(this);
        this.bigView = this.bigView.bind(this);
        this.leaveMeeting = this.leaveMeeting.bind(this);
        this.ChangeVideoForAll = this.ChangeVideoForAll.bind(this);
        this.ChangeAudioForAll = this.ChangeAudioForAll.bind(this);
        this.KickMember = this.kickMember.bind(this);
        this.addTilesToAttendee = this.addTilesToAttendee.bind(this);
        this.addTilesToAttendees = this.addTilesToAttendees.bind(this);
    }


    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    };
    changeVideo = () => {

    }
    acquireVideoElement(tileId) {

        var videoElements = [
            document.getElementById(`myVideo-1`),
            document.getElementById(`myVideo-2`),
            document.getElementById(`myVideo-3`),
            document.getElementById(`myVideo-4`),
            document.getElementById(`myVideo-5`),
            document.getElementById(`myVideo-6`),
            document.getElementById(`myVideo-7`),
            document.getElementById(`myVideo-8`),
        ]
        var videoElements2 = [
            document.getElementById(`myVideo-a1`),
            document.getElementById(`myVideo-a2`),
            document.getElementById(`myVideo-a3`),
            document.getElementById(`myVideo-a4`),
            document.getElementById(`myVideo-a5`),
            document.getElementById(`myVideo-a6`),
            document.getElementById(`myVideo-a7`),
            document.getElementById(`myVideo-a8`),
        ]

        for (let i = 0; i < numOfPersonels; i += 1) {
            if (indexMap[i] === tileId) {

                return {
                    gallery: videoElements[i],
                    speaker: videoElements2[i]
                };

            }


        }
        // Return the next available video element.
        for (let i = 0; i < numOfPersonels; i += 1) {
            if (!indexMap.hasOwnProperty(i)) {
                indexMap[i] = tileId;
                return {
                    gallery: videoElements[i],
                    speaker: videoElements2[i]
                };
            }
        }
        throw new Error('no video element is available');
    }
    releaseVideoElement = tileId => {
        for (let i = 0; i < numOfPersonels; i += 1) {
            if (indexMap[i] === tileId) {
                delete indexMap[i];
                return;
            }
        }
    };
    async componentWillMount() {
        //initialize chime
        if (this.state.test == false) {
            meetingSession = chimeFunctios.initialize(this.state.meetingResponse, this.state.attendeeResponse, this);
            const res = await chimeFunctios.configureAudioFunction(meetingSession, 'myAudio');
            if (res.sucess == false) {
                alert(res.message);
                console.log(res.message);
            }
            const resVideo = await chimeFunctios.configureAudioFunction(meetingSession, 'myAudio');
            if (resVideo.sucess == false) {
                alert(resVideo.message);
                console.log(resVideo.message);
            }
            this.AddObserver(meetingSession);
            chimeFunctios.subscribeToMessages(meetingSession, this);
            var th = this;
            setTimeout(() => {
                if (!th.state.admin)
                    th.syncAttendess();

            }, 5000)
        }


    }
    async componentWillUpdate() {
        if (this.state.activateVideoByAdmin) {
            await this.activateVideo();
            this.setState({ activateVideoByAdmin: false });

        }
    }
    EndMeeting() {
        // setdisplay(true);
        this.setState({ display: true });
        var obj = {
            meetingName: this.state.meetingResponse.Meeting.MeetingId
        }
        axios.post(url + "endMeeting", obj).then(res => {
            this.setState({ display: false });
            this.props.history.push(this.state.url)
            // document.getElementById("attendess").innerHTML = "New text!";
        }).catch(err => {
            // setdisplay(false);
            this.setState({ display: false });
            if (err.response != undefined) {
                console.log(err.response);
                alert(err.response.data)
            }
            else {
                console.log(err);
                alert(err)
            }

        })
    }
    temp = []; temp2 = [];          // these array are to make sure that there is no duplication of tiles
    AddObserver(meetingSession) {
        const observer = {
            audioVideoDidStart: () => {
                this.setState({ display: false });
            },
            // videoTileDidUpdate is called whenever a new tile is created or tileState changes.
            videoTileDidUpdate: tileState => {
                if (!tileState.boundAttendeeId) {
                    return;
                }
                console.log("tile active is --------------------", tileState.active, " video onne is  ", this.temp
                    , " video two is  ", this.temp2);
                if (tileState.boundVideoStream == null && tileState.localTile) {
                    return;
                }

                var videoOne = this.temp.find(x => x.ExternalUserId === tileState.boundAttendeeId);
                var videoOne2 = this.temp2.find(x => x.ExternalUserId === tileState.boundAttendeeId);
                if (videoOne || videoOne2)
                    return;


                var videoElement = this.acquireVideoElement(tileState.tileId);
                this.temp.push({
                    ExternalUserId: tileState.boundAttendeeId,
                    ExternalUser: tileState.boundExternalUserId,
                    tileId: tileState.tileId,
                    isLocal: tileState.localTile,
                    videoComponent: videoElement.gallery,
                    videoComponentID: videoElement.gallery.id


                });
                this.temp2.push({
                    ExternalUserId: tileState.boundAttendeeId,
                    ExternalUser: tileState.boundExternalUserId,
                    tileId: tileState.tileId,
                    isLocal: tileState.localTile,
                    videoComponent: videoElement.speaker,
                    videoComponentID: videoElement.speaker.id
                });
                let th = this;
                if (this.state.admin) {
                    setTimeout(() => {
                        th.addTilesToAttendee(tileState)
                    }, 3000);
                }


                // var o = this.temp.find(x => x.ExternalUserId === tileState.boundAttendeeId);
                // o.videoComponent = videoElement.gallery;
                // o.videoComponentID = videoElement.gallery.id;

                // var o = this.temp2.find(x => x.tileId == tileState.tileId);
                // o.videoComponent = videoElement.speaker;
                // o.videoComponentID = videoElement.speaker.id;

                console.log("video Element is  is -----------------------------------  ", videoElement);
                meetingSession.audioVideo.bindVideoElement(tileState.tileId, videoElement.speaker);
                meetingSession.audioVideo.bindVideoElement(tileState.tileId, videoElement.gallery);

                // var newTile = this.createNewTile(tileState, videoElement.speaker);
                // let t = this.temp2.find(x=>x.ExternalUserId==tileState.boundAttendeeId);
                // if(t)
                // {
                //     t.tileId = newTile.tileId;
                // }

                var videoElementId = videoElement.gallery.id;
                var videoElementInt = parseInt(videoElementId.split("-")[1]);
                console.log("video Element id  is -----------------------------------  ", videoElementInt);


                ElementFunctions.disableVideoPlates(videoElementInt);//now we are activating video so we will deactivate the nameplate
                ElementFunctions.enableVideoComponents(videoElementInt, this.state.admin);            //activate video elements
                ElementFunctions.changeText(videoElementInt, tileState.boundExternalUserId);   //change ttext on nameplate

                // if (tileState.boundExternalUserId === this.state.defaultAttendeeName) { //disabling the loading screen
                //     this.setState({ display: false });
                // }



            },
            videoTileWasRemoved: tileId => {
                console.log("tile is removed- 1-------------------------- ", this.temp, "----------- ", this.temp2, " ------------- ", tileId)
                this.releaseVideoElement(tileId);

                if (this.temp.length > 0) {
                    var obj = this.temp.find(x => x.tileId == tileId)
                    if (obj) {
                        var videoElementInt = parseInt(obj.videoComponentID.split("-")[1]);
                        ElementFunctions.disableVideoComponents(videoElementInt);
                        // ElementFunctions.disableVideoPlates(videoElementInt);
                    }
                }


                this.temp.splice(this.temp.findIndex(x => x.tileId == tileId), 1)
                this.temp2.splice(this.temp2.findIndex(x => x.tileId == tileId), 1)

                //remove name tile and full screen button from video element in speaker mode

                console.log("tile is removed--------------------------- ", this.temp, "----------- ", this.temp2)
            },
            audioVideoDidStop: sessionStatus => {
                const sessionStatusCode = sessionStatus.statusCode();
                if (sessionStatusCode === MeetingSessionStatusCode.AudioCallEnded) {
                    // alert('The session has ended');
                    this.props.history.push(this.state.url)
                    console.log('The session has ended');
                } else {
                    console.log('Stopped with a session status code: ', sessionStatusCode);
                }
            }

        };

        meetingSession.audioVideo.addObserver(observer);
    }
    createNewTile(tileState, element) {
        // let oldTile = meetingSession.audioVideo.getVideoTile(1);
        // let tileState = oldTile.tileState;
      
        let capturedVideotile = meetingSession.audioVideo.addVideoTile(tileState.tileId);
        capturedVideotile.bindVideoStream(tileState.boundAttendeeId, tileState.localTile, tileState.boundVideoStream,
            tileState.videoStreamContentWidth, tileState.videoStreamContentHeight, tileState.streamId);
        capturedVideotile.bindVideoElement(element);
        console.log("captured video Tile is ", capturedVideotile);
        return capturedVideotile.tileState;

    }
    addTilesToAttendee(tileState) {
        console.log("state is -----------------------------------  ", this.state);

        let attendees = this.state.attendees;

        let attendee = attendees.find(x => x.id == tileState.boundAttendeeId);
        if (attendee) {
            console.log("found is -----------------------------------  ", attendee);
            attendee.tileId = tileState.tileId
        }
        this.setState({ attendees: attendees });
        console.log("attenddessssees are  is -----------------------------------  ", attendees);

    }
    addTilesToAttendees() {
        let attendees = this.state.attendees;
        attendees.map(attendee => {
            let temp = this.temp.find(x => x.ExternalUserId == attendee.id);
            if (temp) {
                attendee.tileId = temp.tileId;
            }
        });
        this.setState({ attendees: attendees });
        console.log("latest attendees are ----------------------- ", this.state.attendees);
    }
    changeToGalleryView() {
        this.setState({ viewType: galleryView });

    }
    changeToSpeakerView() {
        this.setState({ viewType: speakerView });
        // if (this.state.test == false) {
        //     meetingSession.audioVideo.bindVideoElement(1, document.getElementById("temp"));
        // }
        console.log("---------------------------------------1111")
    }
    bigView(e) {
        if (this.state.admin) {
            console.log("target is -------------------------------- ", e);
            console.log("temp is -------------------------------- ", this.temp);
            console.log("temp2 is -------------------------------- ", this.temp2);
            this.setState({ collapsed: false });
            let strin = e;
            var videoElementData = this.temp2.find(x => x.videoComponentID === strin);
            console.log("videoElement is --------------------- ", videoElementData);
            console.log("d--------------------- ", this.temp2);
            meetingSession.audioVideo.bindVideoElement(videoElementData.tileId, document.getElementById("myVideo-Big"));
            ElementFunctions.enableBigView(videoElementData.ExternalUser);
            if (videoElementData) {
                var payload = {
                    action: 'bigView',
                    attendeeId: videoElementData.ExternalUserId,
                    attendeeName: videoElementData.ExternalUser,
                };
                chimeFunctios.sendMessage(meetingSession, 'Actions', payload)



            }
        }

    }
    setBigView(payload) {
        console.log("Setting big view for everyone---------")
        this.setState({ collapsed: false });
        var tile = this.temp2.find(x => x.ExternalUserId == payload.attendeeId)
        meetingSession.audioVideo.bindVideoElement(tile.tileId, document.getElementById("myVideo-Big"));
        document.getElementById("globalTileNameBar").style.display = "block"
        document.getElementById("globalTileNameBarSpan").innerHTML = tile.ExternalUser;
        document.getElementById("globalTileNameBarSpan").style.color = "white"
    }
    async activateVideo() {
        // meetingSession.audioVideo.startLocalVideoTile();
        await chimeFunctios.configureAudioFunction(meetingSession, 'myAudio');
        videoFlag = true;
        var payload = {
            action: 'selfEnableVideo',
            attendeeId: this.state.defaultAttendeeID,
            attendeeName: this.state.defaultAttendeeName,
        };
        chimeFunctios.sendMessage(meetingSession, 'Actions', payload);  // NOTIFY OTHER TO ACTIVATE THEIR VIDEO ICON FOR THIS USER  

        //now chnges the icon
        var attendees = this.state.attendees;
        var attendee = attendees.find(x => x.id === this.state.defaultAttendeeID);
        attendee.display = true;
        this.setState({
            attendees: attendees,
            video: true
        });

    }
    deActivateVideo() {
        console.log("deactiavting");



        console.log("temp1: ", this.temp);
        console.log("temp2: ", this.temp2);
        var obj = this.temp.find(x => x.ExternalUserId == this.state.defaultAttendeeID)
        if (obj) {
            var videoElementInt = parseInt(obj.videoComponentID.split("-")[1]);
            ElementFunctions.disableVideoComponents(videoElementInt);       // this function will disable all video overlay along with video.
            //display the video name plate here
            ElementFunctions.enableVideoPlates(videoElementInt, obj);
        }
        this.releaseVideoElement(obj.tileId);
        this.temp.splice(this.temp.findIndex(x => x.isLocal == true), 1);
        this.temp2.splice(this.temp2.findIndex(x => x.isLocal == true), 1);
        meetingSession.audioVideo.stopLocalVideoTile();
        var payload = {
            action: 'selfDisableVideo',
            attendeeId: this.state.defaultAttendeeID,
            attendeeName: this.state.defaultAttendeeName,
        };
        chimeFunctios.sendMessage(meetingSession, 'Actions', payload);              // this will send message to everyone to disable video icons.
        //now chnges the icon
        var attendees = this.state.attendees;
        var attendee = attendees.find(x => x.id === this.state.defaultAttendeeID);
        attendee.display = false;
        this.setState({
            attendees: attendees,
            video: false
        });

        // un bind the big screen
        // meetingSession.audioVideo.bindVideoElement(videoElementData.tileId, document.getElementById("myVideo-Big"));
    }
    Mute() {

        chimeFunctios.mute(meetingSession);
        this.setState({
            audio: false
        });

    }
    unMute() {

        chimeFunctios.unMute(meetingSession);
        this.setState({
            audio: true
        });
    }
    activateVideoOthers(item) {
        var payload = {
            action: 'enableVideo',
            attendeeId: item.id,
            attendeeName: item.title,
        };
        chimeFunctios.sendMessage(meetingSession, 'Actions', payload)
    }
    deActivateVideoOthers(item) {

        var payload = {
            action: 'disableVideo',
            attendeeId: item.id,
            attendeeName: item.title,
        };
        chimeFunctios.sendMessage(meetingSession, 'Actions', payload)
    }
    MuteOthers(item) {
        var payload = {
            action: 'mute',
            attendeeId: item.id,
            attendeeName: item.title,
        };
        chimeFunctios.sendMessage(meetingSession, 'Actions', payload)
    }
    kickMember(item) {
        var payload = {
            action: 'kick',
            attendeeId: item.id,
            attendeeName: item.title,
        };
        chimeFunctios.sendMessage(meetingSession, 'Actions', payload)
    }
    unMuteOthers(item) {

        var payload = {
            action: 'unMute',
            attendeeId: item.id,
            attendeeName: item.title,
        };
        chimeFunctios.sendMessage(meetingSession, 'Actions', payload)
    }
    leaveMeeting() {
        this.setState({ display: true });
        //inform others of meeting end
        // var payload = {
        //     action: 'leavingMeeting',
        //     attendeeId: this.state.defaultAttendeeID,
        //     attendeeName: this.state.defaultAttendeeName,
        // };
        // chimeFunctios.sendMessage(meetingSession, 'Actions', payload)
        var obj = {
            meetingName: this.state.meetingResponse.Meeting.MeetingId,
            attendeeId: this.state.attendeeResponse.Attendee.AttendeeId
        }
        axios.post(url + "leaveMeeting", obj).then(res => {
            this.setState({ dislpay: false });
            meetingSession.audioVideo.removeLocalVideoTile();

            this.props.history.push(this.state.url);
        }).catch(err => {
            // setdisplay(false);

            this.setState({ dislpay: false });
            if (err.response != undefined) {
                console.log(err.response);
                alert(err.response.data)
            }
            else {
                console.log(err);
                alert(err)
            }

        });
    }
    ChangeVideoForAll(bool) {
        console.log("------e", bool);
        if (bool) {
            //Enable all
            var payload = {
                action: 'enableAllVideos',
                attendeeId: this.state.defaultAttendeeID,
                attendeeName: this.state.defaultAttendeeName,
            };

        }
        else {
            //disbale all videos
            var payload = {
                action: 'disableAllVideos',
                attendeeId: this.state.defaultAttendeeID,
                attendeeName: this.state.defaultAttendeeName,
            };
        }
        chimeFunctios.sendMessage(meetingSession, 'Actions', payload)
    }
    ChangeAudioForAll(bool) {
        console.log("------e", bool);
        if (bool) {
            //unmute all
            var payload = {
                action: 'enableAllAudios',
                attendeeId: this.state.defaultAttendeeID,
                attendeeName: this.state.defaultAttendeeName,
            };
        }
        else {
            //mute all
            var payload = {
                action: 'disableAllAudios',
                attendeeId: this.state.defaultAttendeeID,
                attendeeName: this.state.defaultAttendeeName,
            };
        }
        chimeFunctios.sendMessage(meetingSession, 'Actions', payload)
    }
    syncAttendess() {
        console.log("Going to ask admin to share it attendees-----------");
        var payload = {
            action: 'requestAdminToAccessData',
            attendeeId: this.state.defaultAttendeeID,
            attendeeName: this.state.defaultAttendeeName,
        };
        chimeFunctios.sendMessage(meetingSession, 'Actions', payload);
    }
    render() {
        if (this.state.test == false) {

            chimeFunctios.subscribeToAttendeeChanges(meetingSession, this.state.meetingResponse.Meeting.MeetingId, this);

        }
        console.log("attendess are ------------ ", this.state.attendees);
        return (
            <React.Fragment>
                <audio id="myAudio"></audio>

                <Layout>
                    <Layout style={{ backgroundColor: "#1d2737" }}>
                        <Header className="header" style={{ backgroundColor: '#767f89' }} >
                            {/* <Navbar /> */}
                            <a className="navbar-brand logo-ref" target="_blank" href="https://geniteam.com"><img width="100%" height="10%" src={logo} /></a>
                        </Header>

                        <Layout className="site-layout">

                            <Layout>
                                <Content
                                    className="site-layout-background-content"
                                    style={{
                                        // margin: "24px 16px",
                                        // padding: 10,
                                        marginTop: "3%",
                                        marginBottom: "0%",
                                        minHeight: 280
                                    }}
                                >

                                    <div style={this.state.viewType === galleryView ? { display: 'block' } : { display: 'none' }}>
                                        <Row  >
                                            <Col className="secondVideoCol" span={3}>
                                            </Col>
                                            <Col className="secondVideoCol" span={6}>
                                                <span className="avatar_Gallery" id='avatar_Gallery-1'>
                                                </span>
                                                <div class="vimeo-container" id="vimeoContainerGallery-1">
                                                    <div class="mainoverlay">
                                                    </div>
                                                    <div class="overlay" id="overlay1" >
                                                        <UserOutlined className="galleryIcon" />
                                                        <div class="text-container">
                                                            <h5 id="gtileNameBarSpan1" ></h5>
                                                        </div>
                                                    </div>
                                                    <video id="myVideo-1" className='videosElement' >
                                                    </video>
                                                </div>


                                            </Col>
                                            <Col className="secondVideoCol" span={6}>
                                                <span className="avatar_Gallery" id='avatar_Gallery-2'>
                                                </span>
                                                <div class="vimeo-container" id="vimeoContainerGallery-2">
                                                    <div class="mainoverlay">
                                                    </div>
                                                    <div class="overlay" id="overlay2" >
                                                        <UserOutlined className="galleryIcon" />
                                                        <div class="text-container">
                                                            <h5 id="gtileNameBarSpan2" ></h5>
                                                        </div>
                                                    </div>
                                                    <video id="myVideo-2" className='videosElement' >
                                                    </video>
                                                </div>
                                            </Col>
                                            <Col className="secondVideoCol" span={6}>
                                                <span className="avatar_Gallery" id='avatar_Gallery-3'>
                                                </span>
                                                <div class="vimeo-container" id="vimeoContainerGallery-3">
                                                    <div class="mainoverlay">
                                                    </div>
                                                    <div class="overlay" id="overlay3" >
                                                        <UserOutlined className="galleryIcon" />
                                                        <div class="text-container">
                                                            <h5 id="gtileNameBarSpan3" ></h5>
                                                        </div>
                                                    </div>
                                                    <video id="myVideo-3" className='videosElement' >
                                                    </video>
                                                </div> </Col>
                                            <Col className="secondVideoCol" span={3}>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col className="secondVideoCol" span={3}>
                                            </Col>
                                            <Col className="secondVideoCol" span={6}>
                                                <span className="avatar_Gallery" id='avatar_Gallery-4'>
                                                </span>
                                                <div class="vimeo-container" id="vimeoContainerGallery-4">
                                                    <div class="mainoverlay">
                                                    </div>
                                                    <div class="overlay" id="overlay4" >
                                                        <UserOutlined className="galleryIcon" />
                                                        <div class="text-container">
                                                            <h5 id="gtileNameBarSpan4" ></h5>
                                                        </div>
                                                    </div>
                                                    <video id="myVideo-4" className='videosElement' >
                                                    </video>
                                                </div></Col>
                                            <Col className="secondVideoCol" span={6}>
                                                <span className="avatar_Gallery" id='avatar_Gallery-5'>
                                                </span>
                                                <div class="vimeo-container" id="vimeoContainerGallery-5">
                                                    <div class="mainoverlay">
                                                    </div>
                                                    <div class="overlay" id="overlay5" >
                                                        <UserOutlined className="galleryIcon" />
                                                        <div class="text-container">
                                                            <h5 id="gtileNameBarSpan5" ></h5>
                                                        </div>
                                                    </div>
                                                    <video id="myVideo-5" className='videosElement' >
                                                    </video>
                                                </div></Col>
                                            <Col className="secondVideoCol" span={6}>
                                                <span className="avatar_Gallery" id='avatar_Gallery-6'>
                                                </span>
                                                <div class="vimeo-container" id="vimeoContainerGallery-6">
                                                    <div class="mainoverlay">
                                                    </div>
                                                    <div class="overlay" id="overlay6" >
                                                        <UserOutlined className="galleryIcon" />
                                                        <div class="text-container">
                                                            <h5 id="gtileNameBarSpan6" ></h5>
                                                        </div>
                                                    </div>
                                                    <video id="myVideo-6" className='videosElement' >
                                                    </video>
                                                </div></Col>
                                            <Col className="secondVideoCol" span={3}>
                                            </Col>
                                        </Row>

                                    </div>
                                    <div style={this.state.viewType === speakerView ? { display: 'block' } : { display: 'none' }}>
                                        <Row  >
                                            <Col className="secondVideoCol" span={1}>
                                                {/* <button
                                                    onClick={() => {
                                                    meetingSession.audioVideo.pauseVideoTile(1);

                                                    }}>cpature</button>
                                                    <button
                                                    onClick={() => {
                                                    meetingSession.audioVideo.unpauseVideoTile(1);

                                                    }}>cpatures</button>
                                                    <button
                                                    onClick={() => {
                                                  console.log("temp is ------------- ", this.temp);
                                                  console.log("temp2 is ------------- ", this.temp2);
                                                    }}>temps</button> */}
                                            </Col>
                                            <Col className="secondVideoCol" span={3}>

                                                <span className="avatar_Gallery" id='avatar_Speaker-1'>
                                                </span>

                                                <div class="vimeo-container-speaker" id="vimeoContainerSpeaker-1">
                                                    <div class="mainoverlay">
                                                        <a onClick={() => this.bigView("myVideo-a1")}>
                                                            <img id="video_overlays1" src={fullView} />
                                                        </a>
                                                    </div>
                                                    <div class="overlay" id="tileNameBar1" >
                                                        <UserOutlined className="speakerIconSpeaker" />
                                                        <div class="text-container">
                                                            <h7 id="tileNameBarSpan1" ></h7>
                                                        </div>
                                                    </div>
                                                    <video id="myVideo-a1" className='videosElement' >
                                                    </video>
                                                </div>


                                            </Col>
                                            <Col className="secondVideoCol" span={3}>
                                                <span className="avatar_Gallery" id='avatar_Speaker-2'>
                                                </span>
                                                <div class="vimeo-container-speaker" id="vimeoContainerSpeaker-2">
                                                    <div class="mainoverlay">
                                                        <a onClick={() => this.bigView("myVideo-a2")}>
                                                            <img id="video_overlays2" src={fullView} />
                                                        </a>
                                                    </div>
                                                    <div class="overlay" id="tileNameBar2" >
                                                        <UserOutlined className="speakerIconSpeaker" />
                                                        <div class="text-container">
                                                            <h7 id="tileNameBarSpan2" ></h7>
                                                        </div>
                                                    </div>
                                                    <video id="myVideo-a2" className='videosElement' >
                                                    </video>
                                                </div>

                                            </Col>
                                            <Col className="secondVideoCol" span={3}>
                                                <span className="avatar_Gallery" id='avatar_Speaker-3'>
                                                </span>
                                                <div class="vimeo-container-speaker" id="vimeoContainerSpeaker-3">
                                                    <div class="mainoverlay">
                                                        <a onClick={() => this.bigView("myVideo-a3")}>
                                                            <img id="video_overlays3" src={fullView} />
                                                        </a>
                                                    </div>
                                                    <div class="overlay" id="tileNameBar3" >
                                                        <UserOutlined className="speakerIconSpeaker" />
                                                        <div class="text-container">
                                                            <h7 id="tileNameBarSpan3" ></h7>
                                                        </div>
                                                    </div>
                                                    <video id="myVideo-a3" className='videosElement' >
                                                    </video>
                                                </div></Col>


                                            <Col className="secondVideoCol" span={3}>
                                                <span className="avatar_Gallery" id='avatar_Speaker-4'>
                                                </span>
                                                <div class="vimeo-container-speaker" id="vimeoContainerSpeaker-4">
                                                    <div class="mainoverlay">
                                                        <a onClick={() => this.bigView("myVideo-a4")}>
                                                            <img id="video_overlays4" src={fullView} />
                                                        </a>
                                                    </div>
                                                    <div class="overlay" id="tileNameBar4" >
                                                        <UserOutlined className="speakerIconSpeaker" />
                                                        <div class="text-container">
                                                            <h7 id="tileNameBarSpan4" ></h7>
                                                        </div>
                                                    </div>
                                                    <video id="myVideo-a4" className='videosElement' >
                                                    </video>
                                                </div></Col>
                                            <Col className="secondVideoCol" span={3}>
                                                <span className="avatar_Gallery" id='avatar_Speaker-5'>
                                                </span>
                                                <div class="vimeo-container-speaker" id="vimeoContainerSpeaker-5">
                                                    <div class="mainoverlay">
                                                        <a onClick={() => this.bigView("myVideo-a5")}>
                                                            <img id="video_overlays5" src={fullView} />
                                                        </a>
                                                    </div>
                                                    <div class="overlay" id="tileNameBar5" >
                                                        <UserOutlined className="speakerIconSpeaker" />
                                                        <div class="text-container">
                                                            <h7 id="tileNameBarSpan5" ></h7>
                                                        </div>
                                                    </div>
                                                    <video id="myVideo-a5" className='videosElement' >
                                                    </video>
                                                </div></Col>

                                            <Col className="secondVideoCol" span={3}>
                                                <span className="avatar_Gallery" id='avatar_Speaker-6'>
                                                </span>
                                                <div class="vimeo-container-speaker" id="vimeoContainerSpeaker-6">
                                                    <div class="mainoverlay">
                                                        <a onClick={() => this.bigView("myVideo-a6")}>
                                                            <img id="video_overlays6" src={fullView} />
                                                        </a>
                                                    </div>
                                                    <div class="overlay" id="tileNameBar6" >
                                                        <UserOutlined className="speakerIconSpeaker" />
                                                        <div class="text-container">
                                                            <h7 id="tileNameBarSpan6" ></h7>
                                                        </div>
                                                    </div>
                                                    <video id="myVideo-a6" className='videosElement' >
                                                    </video>
                                                </div> </Col>
                                            <Col className="secondVideoCol" span={1}></Col>
                                        </Row>
                                        <Row>
                                            <Col className="secondVideoCol" span={4}></Col>
                                            <Col className="mainVideoColOne" span={16}>

                                                <div class="vimeo-container-global">
                                                    <div class="overlay" id="globalTileNameBar" >

                                                        <UserOutlined className="globlaIcon" />
                                                        <span class="text-container">
                                                            <h3 id="globalTileNameBarSpan" ></h3>
                                                        </span>
                                                    </div>
                                                    <video id="myVideo-Big" className='videosElement' >
                                                    </video>
                                                </div>
                                            </Col>
                                            <Col className="secondVideoCol" span={4}></Col>
                                        </Row>
                                    </div>
                                    <video style={{ display: "none" }}
                                        id="temp">
                                        {/* <source src={vid} type="video/mp4" /> */}
                                    </video>

                                </Content>


                                <Footer style={{ backgroundColor: "#2b323d" }}>
                                    <FooterCard
                                        toggle={this.toggle}
                                        EndMeeting={this.EndMeeting}
                                        LeaveMeeting={this.leaveMeeting}
                                        gallery={this.changeToGalleryView}
                                        speaker={this.changeToSpeakerView}
                                        viewType={this.state.viewType}
                                        deActivateVideo={this.deActivateVideo}
                                        activateVideo={this.activateVideo}
                                        video={this.state.video}
                                        audio={this.state.audio}
                                        mute={this.Mute}
                                        unMute={this.unMute}
                                        admin={this.state.admin}
                                        inviteOthersURL={this.state.meetingName}
                                        attendees={this.state.attendees}
                                    />
                                </Footer>
                            </Layout>

                            {/* <Sider> */}

                            <SiderCard
                                collapsed={this.state.collapsed}
                                attendees={this.state.attendees}
                                defaultAttendeeName={this.state.defaultAttendeeName}
                                admin={this.state.admin}
                                deActivateVideoOthers={this.deActivateVideoOthers}
                                activateVideoOthers={this.activateVideoOthers}
                                unMuteOthers={this.unMuteOthers}
                                MuteOthers={this.MuteOthers}
                                ChangeAudioForAll={this.ChangeAudioForAll}
                                ChangeVideoForAll={this.ChangeVideoForAll}
                                Meeting={this.state.meetingResponse.Meeting}
                                KickMember={this.kickMember}
                            />



                            {/* </Sider> */}

                        </Layout>
                    </Layout>

                    {
                        (this.state.display && this.state.test == false) ? <Layout className='loadingLayout '>
                            <Content className='loadingLayoutContent'>
                                <LoadingOutlined style={{ fontSize: 100 }} spin />
                            </Content>
                        </Layout> : null
                    }

                </Layout>
            </React.Fragment>
        )
    }
}