import React from 'react';
import logo from './logo.svg';
import './App.css';
import './style.scss';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DashBoard from "./components/dsahboard";
import DashbaordOne from "./components/rooms/dsahboard1"
import GalleryView from './components/galleryView';
import Members from "./components/rooms/members";
function App() {
  return (

    <Router>

      <Switch>
        {/* for admin */}
        <Route path="/create" component={DashBoard} exact />
        {/* <Route path="/host" component={DashbaordOne} />
        <Route path="/member" component={Members} />
        <Route path="/meeting" component={Meeting} /> */}
        {/* for attendees */}
        {/* <Route path="/join" component={JoinMeeting} /> */}
        <Route path="/galleryView" component={GalleryView} />
        <Route path="/:meetingName" component={DashbaordOne} />
        <Route path="/member/:meetingName" component={Members} />
      </Switch>

    </Router >

  );
}

export default App;
