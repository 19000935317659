module.exports = {
    enableVideoPlates(videoElementInt, obj) {
        try {
            document.getElementById('avatar_Gallery-' + videoElementInt).style.display = 'block';
            document.getElementById('avatar_Gallery-' + videoElementInt).innerHTML = obj.ExternalUser[0] + obj.ExternalUser[1];

            document.getElementById('avatar_Speaker-' + videoElementInt).style.display = 'block';
            document.getElementById('avatar_Speaker-' + videoElementInt).innerHTML = obj.ExternalUser[0] + obj.ExternalUser[1];



        } catch (error) {
            console.error("error occured ", error);
        }

    },
    disableVideoPlates(videoElementInt) {
        try {
            console.log("disableVideoPlates -- ", videoElementInt);
            document.getElementById('avatar_Gallery-' + videoElementInt).style.display = 'none';
            document.getElementById('avatar_Speaker-' + videoElementInt).style.display = 'none';

        } catch (error) {
            console.error("error occured ", error);
        }
    },
    disableVideoComponents(videoElementInt) {
        try {
            document.getElementById("video_overlays" + videoElementInt).style.display = "none"  // enlarge icon in speaker mode
            document.getElementById("tileNameBar" + videoElementInt).style.display = "none";    // nameplate in speaker mode
            document.getElementById("globalTileNameBar").style.display = "none";                // global nameplate
            document.getElementById("overlay" + videoElementInt).style.display = "none";        // nameplate in gallery mode
            document.getElementById('myVideo-a' + videoElementInt).style.display = 'none';      //speaker video component
            document.getElementById('myVideo-' + videoElementInt).style.display = 'none';      //Gallery video component
        } catch (error) {
            console.error("error occured ", error);
        }
    },
    enableVideoComponents(videoElementInt, admin) {
        try {

            if (admin)
                document.getElementById("video_overlays" + videoElementInt).style.display = "block"  // enlarge icon in speaker mode
            document.getElementById("tileNameBar" + videoElementInt).style.display = "block";  // nameplate in speaker mode
            document.getElementById("overlay" + videoElementInt).style.display = "block";   // nameplate in gallery mode
            document.getElementById('myVideo-a' + videoElementInt).style.display = 'block';      //speaker video component
            document.getElementById('myVideo-' + videoElementInt).style.display = 'block';      //Gallery video component
        } catch (error) {
            console.error("error occured ", error);
        }
    },
    changeText(videoElementInt, text) {
        document.getElementById("tileNameBarSpan" + videoElementInt).innerHTML = text;
        document.getElementById("tileNameBarSpan" + videoElementInt).style.color = "white"
        document.getElementById("gtileNameBarSpan" + videoElementInt).innerHTML = text;
    },
    enableBigView(text) {
        try {
            document.getElementById("globalTileNameBar").style.display = "block"
            document.getElementById("globalTileNameBarSpan").innerHTML = text;
            document.getElementById("globalTileNameBarSpan").style.color = "white"
        } catch (error) {
            console.error("error occured ", error);
        }
    }
}