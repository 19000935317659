import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";

import { Layout, Menu, Row, Col, List, Collapse, Button, Switch, Popconfirm, message, Input } from "antd";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined, LoadingOutlined, AudioMutedOutlined, AudioOutlined, EyeOutlined, EyeInvisibleFilled, CloseOutlined, InfoCircleFilled,
    TeamOutlined, DesktopOutlined
} from "@ant-design/icons";
import dropDown from "../assets/images/siderbarImages/dropdown.png";
import hostMic from "../assets/images/siderbarImages/hostMic.png";
import leaveMeeting from "../assets/images/siderbarImages/leaveMeeting.png";
import micOff from "../assets/images/siderbarImages/micOff.png";
import micOn from "../assets/images/siderbarImages/micOn.png";
import searchIcon from "../assets/images/siderbarImages/searchIcon.png";
import speakerView from "../assets/images/siderbarImages/speakerView.png";
import videoOn from "../assets/images/siderbarImages/vdeoOn.png";
import videoOff from "../assets/images/siderbarImages/videoOff.png";
import axios from 'axios';
import { url } from "../config"
const { Header, Footer, Sider, Content } = Layout;
const { Panel } = Collapse;
export default class SiderCard extends React.Component {
    constructor(props) {
        super(props);
        this.confirm = this.confirm.bind(this);
        this.state = {
            mute: false,
            video: true
        }
    }
    componentWillUpdate(prevProps) {
        // this.setprops({})
        console.log("sidebar : props are : -----------------", this.props);
    }
    // audioChanged(e) {
    //     console.log("------e", e);
    //     this.props.ChangeAudioForAll(e);
    // }
    // videChanged(e) {
    //     this.props.ChangeVideoForAll(e);
    // }
    confirm(e) {
        console.log(e);
        message.success('Click on Yes');
        console.log("sidebar : props are :sss -----------------", this.props);
        this.props.KickMember(e);
        var obj = {
            meetingName: this.props.Meeting.MeetingId,
            attendeeId: e.id
        }
        setTimeout(() => {
            axios.post(url + "leaveMeeting", obj).then(res => {
                // this.setState({ dislpay: false });
                // this.props.history.push(this.state.url)

            }).catch(err => {
                // setdisplay(false);
                // meetingSession.audioVideo.removeLocalVideoTile();
                // this.setState({ dislpay: false });
                if (err.response != undefined) {
                    console.log(err.response);
                    alert(err.response.data)
                }
                else {
                    console.log(err);
                    alert(err)
                }

            })
        }, 1000)


    }
    inputChange(e) {
        console.log(e.target.value);
    }
    render() {
        return (
            <Sider id='sider11' style={{ backgroundColor: "#efefef", display: 'flex', flexDirection: "column", justifyContent: 'space-between' }}
                width='22%'

                trigger={null} collapsible collapsed={this.props.collapsed} collapsedWidth='0%'>
                {/* <div className="logo" /> */}
                <Collapse bordered={false} defaultActiveKey={['1']} style={{ height: '90%' }}>
                    <Panel extra={<Row>
                        <Col span={12}>
                            <h6 className='siderHeading'>Participants ({this.props.attendees.length})</h6>
                        </Col>
                        <Col span={12}>


                        </Col>
                    </Row>} key="1">
                        {/* <Input onChange={this.inputChange} /> */}
                        <List
                            // header={}
                            size="large"
                            // footer={<Button>Mute All</Button>}

                            itemLayout="vertical"
                            dataSource={this.props.attendees}
                            renderItem={item => (
                                <List.Item className="siderList" >
                                    <Row>
                                        <Col span={18} >
                                            <span className="avatar">{item.title[0]}{item.title[1]}<span
                                                className="status online"></span></span>{' '}
                                            <span className="avatarText">{item.isAdmin ? item.title + ' (Host)' : item.title}</span></Col>

                                        <Col span={6} className="siderIcon">
                                            {
                                                item.title === this.props.defaultAttendeeName
                                                    ?
                                                    (item.mute == true ?
                                                        <img className='siderAudio' src={micOff} /> :
                                                        <img className='siderAudio' src={micOn} />)
                                                    : (this.props.admin ? (item.mute == true ? <a onClick={() => this.props.unMuteOthers(item)} ><img className='siderAudio' src={micOff} /></a> :
                                                        <a onClick={() => this.props.MuteOthers(item)}><img className='siderAudio' src={micOn} /></a>) :
                                                        (item.mute == true ? <img className='siderAudio' src={micOff} /> :
                                                            <img className='siderAudio' src={micOn} />))
                                            }
                                            {' '}
                                            {
                                                item.title === this.props.defaultAttendeeName
                                                    ?
                                                    (item.display == true ?
                                                        <img className='siderVideo' src={videoOn} /> :
                                                        <img className='siderVideo' src={videoOff} />)
                                                    : (this.props.admin ? (item.display == true ? <a onClick={() => this.props.deActivateVideoOthers(item)} ><img className='siderVideo' src={videoOn} /></a> :
                                                        <a onClick={() => this.props.activateVideoOthers(item)}><img className='siderVideo' src={videoOff} /></a>) :
                                                        (item.display == true ? <img className='siderVideo' src={videoOn} /> :
                                                            <img className='siderVideo' src={videoOff} />))
                                            }
                                            {
                                                this.props.admin ? (
                                                    item.title === this.props.defaultAttendeeName ? null :
                                                        <Popconfirm
                                                            title="Kick out this user?"
                                                            onConfirm={() => this.confirm(item)}
                                                            // onCancel={cancel}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <a><img className='siderLeave' src={leaveMeeting} /></a>
                                                        </Popconfirm>

                                                ) : null
                                            }



                                        </Col>
                                    </Row>
                                </List.Item>
                            )}
                        />
                    </Panel>

                </Collapse>

                <Row>
                    <Col span={3}></Col>
                    <Col span={6}>
                        {this.props.admin ? (
                            this.state.mute ? <Button  type="primary" onClick={this.unMuteAll}>Un Mute All</Button> : <Button type="primary" onClick={this.muteAll}>Mute All</Button>
                        ) : null}

                    </Col>
                    <Col span={2}></Col>
                    <Col span={6}>
                        {this.props.admin ? (
                            // this.state.video ? <Button type="primary"  onClick={this.videoOff}>Video Off</Button> : <Button type="primary"  onClick={this.videoOn}>Video On</Button>
                            <Button type="primary"  onClick={this.videoOff}>Video Off</Button>
                        ) : null}
                    </Col>
                    <Col span={3}></Col>
                    {/* {
                        this.props.admin ? (<span><Switch onChange={this.props.ChangeAudioForAll} checkedChildren={<AudioOutlined />} unCheckedChildren={<AudioMutedOutlined />} defaultChecked />
                            {' '}   <Switch onChange={this.props.ChangeVideoForAll} checkedChildren={<EyeInvisibleFilled />} unCheckedChildren={<EyeOutlined />} defaultChecked />
                        </span>
                        ) : null
                    } */}
                </Row>
            </Sider>
        );
    }
    muteAll = () => {
        this.props.ChangeAudioForAll(false);
        this.setState({
            mute: true
        })
    }
    unMuteAll = () => {
        this.props.ChangeAudioForAll(true);
        this.setState({
            mute: false
        })
    }
    videoOff = () => {
        this.props.ChangeVideoForAll(false);
        this.setState({
            video: false
        })
    }
    videoOn = () => {
        this.props.ChangeVideoForAll(true);
        this.setState({
            video: true
        })
    }
}

