import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button, Card, CardBody, CardGroup, Col, Container, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { Form, Input, Checkbox, Spin } from 'antd'
import "antd/dist/antd.css";
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined } from '@ant-design/icons';
import geniLogo from "../../assets/images/geniLogo.png";
import { url } from "../../config";
export default class DashbaordOne extends Component {
  constructor(props) {
    super(props);
    console.log("props are ------------------------------- ", props);
    var params = props.match.params;

    this.state = {
      loading: false,
      member: "host",
      meetingName: params.meetingName,
      url: props.match.url
    }
  }

  componentDidMount() {

   // setTimeout(function(){ 
      this.handleSubmit("aaaa");
   // }, 3000);

   
}
  handleSubmit = values => {

    console.info("Received values of form: ", values);
    this.setState({ loading: true })
    var obj = {
      name: "player"+Math.floor(Math.random() * 1000),
      meetingName: this.state.meetingName,
    }
    axios.post(url + 'createMeeting', obj).then(res => {
      res.data.meetingName = this.state.meetingName;
      if (this.state.member == 'host') {
        res.data.admin = true
      }
      else res.data.admin = false
      res.data.url = this.state.url;
      var resp = JSON.stringify(res.data);
      localStorage.setItem("meeting", resp);
      this.props.history.push("/GalleryView");
    }).catch(err => {
      this.setState({ loading: false })
      if (err.response != undefined) {
        console.log(err.response);
        alert(err.response.data)
      }
      else {
        console.log(err);
        alert(err)
      }

    })


  };
  render() {
    return (
      <div className="app flex-row align-items-center">
       

      </div>
    );
  }
}

